import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

import SEO from "../utils/seo"

import { gql, useQuery } from "@apollo/client"

import Loader from "../components/loadingAnim/loader"

//import Results from "../components/search/results"
import ResultsList from "../components/search/resultsList"
import { Link } from "gatsby"
import { WishListContext } from "../utils/wishListProvider"

const Wrapper = styled.div.attrs({
  className: "resultsWrapper",
})`
  grid-area: filters;
  margin: 0 auto;
  max-width: 982px;
  margin-top: 2rem;
`
const Count = styled.div.attrs({
  className: "count",
})`
  grid-area: count;
  text-align: center;
  font-size: 0.7rem;
  padding: 1rem;

  @media ${props => props.theme.mq.sm} {
    font-size: 1.2rem;
  }

  @media ${props => props.theme.mq.md} {
    text-align: left;
    padding: unset;
    font-size: 1.2rem;
  }
`
const MobileFilterOpen = styled.div.attrs({
  className: "mobileFilterButton",
})`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.75rem;
  text-align: center;
  background-color: white;
  z-index: 21;
  box-shadow: 0px -3px 6px #00000029;

  span {
    padding: 0.5rem;
    border: 1px solid #333;
    width: 100%;
    display: block;
  }

  &.active span {
    color: ${props => props.theme.colors.primary};
    border: 2px solid ${props => props.theme.colors.primary};
    font-style: italic;
  }

  @media ${props => props.theme.mq.sm} {
    padding: 1rem;
  }

  @media ${props => props.theme.mq.md} {
    display: none;
  }
`

const Loading = styled.div`
  grid-area: results;

  .inner {
    position: relative;
    z-index: 2;
  }

  .errorMsg {
    text-align: center;
  }
`

const WishListPage = ({ results = true }) => {
  const { wishList } = useContext(WishListContext)

  //initial filter state variables
  const initialState = {
    resetAll: false,
    sortOrder: "",
    where: null,
    who_adults: "2",
    who_children: "0",
    who_infants: "0",
    who_pets: "0",
    with_bedrooms: "1",
    with_bathrooms: "1",
    when_from: null,
    when_to: null,
    what: {
      wifi: false,
      garden: false,
      patio: false,
      parking: false,
      woodburner: false,
      openfire: false,
      seaview: false,
      carpoint: false,
      balcony: false,
      lift: false,
      tv: false,
      dishwasher: false,
      washingmachine: false,
      heating: false,
      garage: false,
      hairdryer: false,
      bathtowels: false,
      // beachtowels: false,
      bedlinen: false,
      bbq: false,
      aircon: false,
      hottub: false,
      roofterrace: false,
      towncentre: false,
      countryside: false,
      skytv: false,
      showerroom: false,
      kingbed: false,
      walkbeach: false,
      walkpub: false,
      detached: false,
      romantic: false,
      family: false,
      hiddengem: false,
      beachchalet: false,
      large: false,
      countrywalks: false,
      luxury: false,
      peaceful: false
    },
    filteredACOptions: [],
    showACOptions: false,
    userACInput: "",
    activePanel: null,
  }

  //rendered property state
  // const [renderedPropertyState, setRenderedPropertyState] = useState({
  //   filteredProperties: [],
  //   renderComplete: false,
  // })

  //filters state
  const [propertyFilterState] = useState(
    getStoredComponentState()
  )

  //console.log("state", propertyFilterState);

  //set local storage
  useEffect(() => {
    typeof window !== `undefined` && window.localStorage.setItem("filterState", JSON.stringify(propertyFilterState))
  }, [propertyFilterState])

  //get locally stored state
  function getStoredComponentState() {
    const storedState = typeof window !== `undefined` && window.localStorage.filterState
    return storedState ? JSON.parse(storedState) : initialState
  }

  //console.log("local storage", getStoredComponentState());

  //reset state
  // const resetState = () => {
  //   setPropertyFilterState(initialState)
  //   setPropertyFilterState(setPropertiesState => ({
  //     ...setPropertiesState,
  //     resetAll: true,
  //   }))
  // }

  //who selects visibility state
  const [showState, setShowState] = useState({
    showMobileFilter: false,
  })

  //render count
  const renderCount = () => {
    if (
      propertyCount()
    ) {
      //if (results === true) {
      //console.log(renderedPropertyState.filteredProperties.length)
      return (
        <Count>
          You have {propertyCount() ? propertyCount() : "no"} propert{propertyCount() === 1 ? "y " : "ies "}
          in your wish list
        </Count>
      )
    } else {
      return
    }
  }

  // This query is executed at run time by Apollo.
  const WISH_LIST_QUERY = gql`
    query GetWishProperties($proprefs: [String!]) {
      getProperties(query: { which: { proprefs: $proprefs } }) {
        properties {
            propRef: ref
            name
            wpurl
            rating
            prices {
              priceBase
            }
            description {
              short
            }
            sleeps
            bedrooms
            pets
            where: address {
              town
              coords {
                lat
                lon
              }
            }
            attributes{
              name
              value
            }
            images {
              description
              url
            }
          }
      }
    }
  `

  // Our Apollo Client query that does the heavy lifting to fetch our data and returns data to loadDynamic handler
  const { loading, error, data } = useQuery(WISH_LIST_QUERY, {
    variables: { proprefs: wishList },
    ssr: false,
    //onCompleted: loadDynamic,
  })

  // function loadDynamic(data) {
  //   //if (data !== undefined)
  //   //console.log(data)

  //   // Update state with our new list of filtered properties in selected sort order
  //   return (
  //     data &&
  //     setRenderedPropertyState(setPropertiesState => ({
  //       ...setPropertiesState,
  //       filteredProperties: data.getProperties.properties.sort(
  //         compareValues(
  //           propertyFilterState.sortOrder[0],
  //           propertyFilterState.sortOrder[1]
  //         )
  //       ),
  //       renderComplete: true,
  //     }))
  //   )
  // }

  function propertyCount() {
    if (wishList.length && data?.getProperties?.properties) {
      return data.getProperties.properties.length;
    }
    return null;
  }

  //no properties message
  const propertiesLoaded = () => {
    if (
      wishList.length &&
      data?.getProperties?.properties
    ) {
      return (
        <ResultsList
          properties={data.getProperties.properties}
        />
      )
    } else {
      return (
        <Loading>
          <div className="errorMsg">
            Sorry there are no properties in your wish list
            <br />
            <br />
            <Link to="/properties">Select more properties</Link>
          </div>
        </Loading>
      )
    }
  }

  //render property results
  const renderResults = () => {
    if (results === true) {
      return (
        <>
          <Loading>
            <div className="inner">{loading && <Loader />}</div>
            {error && <p>Error: ${error.message}</p>}
          </Loading>
          {data &&
            data.getProperties &&
            data.getProperties.properties &&
            propertiesLoaded()}
        </>
      )
    } else {
      return
    }
  }

  //toggle mobile filter
  const mobileFilterOpen = () => {
    if (showState.showMobileFilter === false) {
      setShowState(setMobileFilterState => ({
        ...setMobileFilterState,
        showMobileFilter: true,
      }))
    } else {
      window.scrollTo(0, 0)

      setShowState(setMobileFilterState => ({
        ...setMobileFilterState,
        showMobileFilter: false,
      }))
    }
  }

  //render mobile filter button
  const renderMobileFilter = () => {
    if (results === true) {
      return (
        <MobileFilterOpen
          onClick={mobileFilterOpen}
          className={showState.showMobileFilter === true ? "active" : ""}
        >
          <span>
            {showState.showMobileFilter === true
              ? "You have " +
              propertyCount() +
              " propert" + (propertyCount() === 1 ? "y" : "ies") + " in your wish list"
              : "Filter"}
          </span>
        </MobileFilterOpen>
      )
    } else {
      return
    }
  }

  return (
    <>
      <SEO title="Wish List" />

      <SEO
        title="Wish List"
        description="View all your saved properties"
        canonical="/wishlist"
        article={false}
      />

      <Wrapper>
        {renderCount()}

        {renderResults()}

        {renderMobileFilter()}
      </Wrapper>
    </>
  )
}

export default WishListPage
